<template>
  <div>
    <v-row>
      <v-col v-if="ethocaChargebackEventPrices" cols="12" sm="6">
        <v-card>
          <v-card-title class="align-start">
            <span>MC Event Price</span>
          </v-card-title>

          <v-card-text>
              <v-row>
                <v-col cols="12">
                  <ChargebackPriceConfigList :chargeback-event-prices="ethocaChargebackEventPrices" />
                </v-col>
                <v-col cols="12">
                  <v-btn @click="openPriceConfigModal(priceConfigServiceType.ETHOCA, ethocaChargebackEventPrices)" color="primary"> Change MC Event Price </v-btn>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="visaCdrnChargebackEventPrices" cols="12" sm="6">
        <v-card>
          <v-card-title class="align-start">
            <span>CDRN Visa Event Price</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <ChargebackPriceConfigList :chargeback-event-prices="visaCdrnChargebackEventPrices" />
              </v-col>
              <v-col cols="12">
                <v-btn @click="openPriceConfigModal(priceConfigServiceType.VISA_CDRN, visaCdrnChargebackEventPrices)" color="primary"> Change Visa CDRN Event Price </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="visaRdrChargebackEventPrices" cols="12" sm="6">
        <v-card>
          <v-card-title class="align-start">
            <span>RDR Visa Case Prices</span>
          </v-card-title>

          <v-card-text>
            <v-col cols="12">
              <ChargebackPriceConfigList :chargeback-event-prices="visaRdrChargebackEventPrices" />
            </v-col>
            <v-col cols="12">
              <v-btn @click="openPriceConfigModal(priceConfigServiceType.VISA_RDR, visaRdrChargebackEventPrices)" color="primary"> Change Visa RDR Event Price </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title class="align-start">
            <span>Recalculate Chargeback Event Prices for Merchants using Price Grid</span>
          </v-card-title>

          <v-card-text>
            <v-col cols="12">
              <v-btn @click="recalculateChargebackPrices()" color="primary"> RECALCULATE </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ChargebackPriceConfigModal @closePriceModal="closePriceModal" />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import { eventBus } from '@/main';
import { required } from '@core/utils/validation';
import PriceConfigServiceType from '@/enums/report-service-type.enum';
import ChargebackPriceConfigList
  from '@/views/chargebacks/chargeback-price-configs/_partials/ChargebackPriceConfigList.vue';
import ChargebackPriceConfigModal
  from '@/views/chargebacks/chargeback-price-configs/components/ChargebackPriceConfigModal.vue';
import Vue from 'vue';

export default {
  components: { ChargebackPriceConfigModal, ChargebackPriceConfigList },
  setup() {
    const priceConfigByType = {
      [PriceConfigServiceType.ETHOCA]: ref(null),
      [PriceConfigServiceType.VISA_CDRN]: ref(null),
      [PriceConfigServiceType.VISA_RDR]: ref(null),
    };

    const visaRdrChargebackEventPrices = computed(() => priceConfigByType[PriceConfigServiceType.VISA_RDR].value);
    const visaCdrnChargebackEventPrices = computed(() => priceConfigByType[PriceConfigServiceType.VISA_CDRN].value);
    const ethocaChargebackEventPrices = computed(() => priceConfigByType[PriceConfigServiceType.ETHOCA].value);

    const priceConfigServiceType = PriceConfigServiceType;

    const openPriceConfigModal = (type, prices) => {
      eventBus.$emit('chargeback-price-config.modal.show', ('chargeback-price-config.modal.show', {
        type: type,
        prices: prices.map(price =>  { return { price: price.price, tier: price.tier }}),
      }));
    };

    const recalculateChargebackPrices = () => {
      Vue.$confirm({
        message: 'Are you sure want to run the Chargeback Events prices recalculation process for all merchants with enabled Price Grid?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store.dispatch('websitePrice/recalculateChargebackPrices').then(() => {
              Vue.notify({
                type: 'success',
                title: 'Recalculate Chargeback Prices',
                text: 'Recalculation is finished, please see Slack log channel for more details',
                duration: 10000,
              });
            }).catch((error) => {
              Vue.notify({
                type: 'error',
                title: 'Recalculate Chargeback Prices',
                text: `Recalculation failed, reason: ${error.message}. Please see Slack log channel for more details`,
              });
            });
          }
        },
      });
    };

    store.dispatch('chargebackPriceConfig/fetchEventPriceConfig').then(chargebackEventPriceConfig => {
      if (chargebackEventPriceConfig.length > 0) {
        Object.keys(priceConfigByType).forEach(type => {
          priceConfigByType[type].value = chargebackEventPriceConfig.filter(
            price => price.serviceType === type,
          );
        });
      }
    });

    const findPriceConfigIndexByTier = (prices, tier) => {
      return prices.findIndex(price => price.tier === tier);
    };

    const closePriceModal = (data) => {
      data.prices.forEach((price) => {
        const priceConfig = priceConfigByType[data.type];
        if (priceConfig) {
          priceConfig.value[findPriceConfigIndexByTier(priceConfig.value, price.tier)].price = price.price;
        }
      });
    };

    return {
      visaRdrChargebackEventPrices,
      visaCdrnChargebackEventPrices,
      ethocaChargebackEventPrices,
      openPriceConfigModal,
      recalculateChargebackPrices,
      priceConfigServiceType,
      closePriceModal,
      validators: { required },
    };
  },
};
</script>
