<template>
  <v-list class="pt-1">
    <v-list-item
      v-for="(priceConfig, index) in chargebackEventPrices"
      :key="index"
      class="d-flex px-0"
      :class="index > 0 ? 'mt-4' : ''"
    >
      <div class="d-flex align-center flex-grow-1 flex-wrap">
        <div v-if="chargebackEventPrices.length > 1" class="me-2">
          <h4 class="font-weight-semibold text-no-wrap">
            {{ priceConfig.tier }}
          </h4>
        </div>

        <v-spacer v-if="chargebackEventPrices.length > 1"></v-spacer>

        <div class="d-flex align-center">
          <span class="text-base font-weight-semibold text--primary me-1">
            {{ priceConfig.price }} EUR
          </span>
        </div>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    chargebackEventPrices: {
      type: Array,
      required: true,
    },
  },
};
</script>
