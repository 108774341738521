<template>
  <v-dialog v-model="priceConfigModal" max-width="470" persistent>
    <v-form ref="form" v-model="valid" @submit.prevent="updatePrice">
        <v-card class="generate-fake-event-modal">
          <v-card-title>{{chargebackPriceTypeClearName}} event price</v-card-title>
          <v-card-text>
            <div class="row" v-for="(price, index) in chargebackPrice.prices" :key="index">
              <div class="col-12">
                <v-text-field
                  class="chargeback-price-config-old-price"
                  v-model="chargebackOldPrices[index].price"
                  prefix="€"
                  outlined
                  disabled
                  dense
                  type="number"
                  :rules="[validators.required]"
                  :label="`${chargebackPriceTypeClearName} ${chargebackPrice.prices.length > 1 ? chargebackOldPrices[index].tier : ''} Event Old Price`"
                ></v-text-field>
              </div>
              <div class="col-12">
                <v-text-field
                  class="chargeback-price-config-new-price"
                  v-model="chargebackPrice.prices[index].price"
                  prefix="€"
                  outlined
                  dense
                  type="number"
                  :rules="[validators.required, validators.positiveNumberValidator]"
                  :label="`${chargebackPriceTypeClearName} ${chargebackPrice.prices.length > 1 ? price.tier : ''} Event New Price`"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <v-checkbox
                  v-model="chargebackPrice.isUpdatePassEvents"
                  label="Recalculate past events"
                ></v-checkbox>
              </div>
            </div>
            <div v-if="chargebackPrice.isUpdatePassEvents" class="row">
              <div class="col-6">
                <v-menu
                  v-model="isShowFromDatePickerDialog"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="From"
                      v-model="chargebackPrice.from"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="chargebackPrice.from"
                    :max="new Date().toISOString().substr(0, 10)"
                    @input="date => {
                      if (new Date(date) > new Date(chargebackPrice.to)) {
                        chargebackPrice.from = chargebackPrice.to;
                      }
                      isShowFromDatePickerDialog = false;
                    }"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-6">
                <v-menu
                  v-model="isShowToDatePickerDialog"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="To"
                      v-model="chargebackPrice.to"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="chargebackPrice.to"
                    :max="new Date().toISOString().substr(0, 10)"
                    @input="date => {
                      if (new Date(date) < new Date(chargebackPrice.from)) {
                        chargebackPrice.to = chargebackPrice.from;
                      }
                      isShowToDatePickerDialog = false;
                    }"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit"> Change {{chargebackPriceTypeClearName}} event price </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="priceConfigModal = false; chargebackPrice = { ...defaultChargebackPrice };"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
  </v-dialog>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import { positiveNumberValidator, required } from '@core/utils/validation';
import Vue from 'vue';
import store from '@/store';
import PriceConfigServiceType, {
  PriceConfigServiceTypeClearName
} from '@/enums/report-service-type.enum';
import { eventBus } from '@/main';

export default {
  methods: { positiveNumberValidator },
  setup(data, ctx) {
    const defaultChargebackPrice = {
      type: null,
      prices: [],
      isUpdatePassEvents: false,
      from: new Date(Date.now()).toISOString().substr(0, 10),
      to: new Date(Date.now()).toISOString().substr(0, 10),
    };
    const chargebackPriceTypeClearName = ref(false);
    const priceConfigModal = ref(false);
    const chargebackOldPrices = ref([]);
    const isShowFromDatePickerDialog = ref(false);
    const isShowToDatePickerDialog = ref(false);
    const form = ref(null);
    const valid = ref(false);

    const chargebackPrice = ref({ ...defaultChargebackPrice });

    const updatePrice = () => {
      const isFormValid = form.value.validate();

      if (!isFormValid) {
        return;
      }
      let message = `Are you sure you want to change ${chargebackPriceTypeClearName.value} events price`;

      if (chargebackPrice.value.type !== PriceConfigServiceType.VISA_RDR) {
        message += ` from ${chargebackOldPrices.value[0].price} to ${chargebackPrice.value.prices[0].price}`;
      }

      if (chargebackPrice.value.isUpdatePassEvents) {
        message += ` for the period from ${chargebackPrice.value.from} to ${chargebackPrice.value.to}?`;
      } else {
        message += '?';
      }

      Vue.$confirm({
        message,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store.dispatch('chargebackPriceConfig/patchEventPrice', chargebackPrice.value).then(() => {
              closeModal();
            });
          }
        },
      });
    };

    const closeModal = () => {
      priceConfigModal.value = false;
      ctx.emit('closePriceModal', { type: chargebackPrice.value.type, prices: chargebackPrice.value.prices });
      chargebackPrice.value = { ...defaultChargebackPrice };
    };

    onMounted(() => {
      eventBus.$on('chargeback-price-config.modal.show', (data) => {
        chargebackPrice.value.type = data.type;
        chargebackPriceTypeClearName.value = PriceConfigServiceTypeClearName[data.type];
        chargebackOldPrices.value = data.prices.map(price => { return { ...price }});
        chargebackPrice.value.prices = data.prices;
        priceConfigModal.value = true;
      });
    });

    return {
      priceConfigModal,
      chargebackPrice,
      isShowFromDatePickerDialog,
      isShowToDatePickerDialog,
      chargebackPriceTypeClearName,
      chargebackOldPrices,
      defaultChargebackPrice,
      valid,
      form,
      updatePrice,
      closeModal,
      validators: { required, positiveNumberValidator },
    };
  },
};
</script>
