const PriceConfigServiceType = {
  ETHOCA: 'ethocaService',
  VISA_CDRN: 'visaCdrnService',
  VISA_RDR: 'visaRdrService',
};

export const PriceConfigServiceTypeClearName = {
  ethocaService: 'MC',
  visaRdrService: 'Visa RDR',
  visaCdrnService: 'Visa CDRN',
};

export default PriceConfigServiceType;
